





















// Import utils ------------------------------------------------------------------------------------
import { Layout, layoutFactory } from '@/utils/layouts.utils';
import { createNotifications } from '@/utils/notifications.utils';
// Import vendors ----------------------------------------------------------------------------------
import { useService } from '@xstate/vue';
import { computed, watch } from '@vue/composition-api';
import moment from 'moment';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocore, usePodocoreModule } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import LayoutDefaultNavbar from '@/layouts/default/components/navbar/LayoutDefaultNavbar.vue';
import LayoutDefaultSidebar from '@/layouts/default/components/sidebar/LayoutDefaultSidebar.vue';
import Notifications from '@/layouts/default/components/Notifications.vue';
import ElementNotCompatibleBrowserSnackbar from '@/components/elements/ElementNotCompatibleBrowserSnackbar.vue';
import DialogOnboarding from '@/components/dialogs/DialogOnboarding.vue';
import DialogWorkspaceCreate from '@/components/dialogs/DialogWorkspaceCreate.vue';
import DialogSaasCheckout from '@/components/dialogs/DialogSaasCheckout.vue';
import Consents from './components/Consents.vue';
// import { useLocalStorage } from '@vueuse/core';
// -------------------------------------------------------------------------------------------------

export default layoutFactory(
  {
    layout: Layout.Default
  },
  {
    components: {
      LayoutDefaultNavbar,
      LayoutDefaultSidebar,
      ElementNotCompatibleBrowserSnackbar,
      Notifications,
      DialogOnboarding,
      DialogWorkspaceCreate,
      DialogSaasCheckout,
      Consents
    },
    setup() {
      createNotifications();

      const { state } = useService(usePodocore().service);

      const busModule = usePodocoreModule('bus');

      /* const newVersionBanner = useLocalStorage(
        'new-version:2_1',
        Date.now() < new Date('2023-02-08T00:00:00.00Z').getTime()
      ); */

      const hasIncomingMaintenance = computed(() => {
        return !!(state.value.context as any).info?.isIncoming();
      });

      const maintenanceStartIn5min = computed(() => {
        const duration = moment.duration(moment(new Date()).diff((state.value.context as any).info?.start));
        var minutes = duration.asMinutes();
        return minutes < 5;
      });

      watch(maintenanceStartIn5min, (value) => {
        if (value)
          busModule.publish(
            busModule.events.createNotification({
              message: {
                ...(state.value.context as any).info,
                type: 'incomingMaintenance'
              }
            })
          );
      });

      watch(
        hasIncomingMaintenance,
        (value) => {
          if (value)
            busModule.publish(
              busModule.events.createNotification({
                message: {
                  ...(state.value.context as any).info,
                  type: 'incomingMaintenance'
                }
              })
            );
        },
        { immediate: true }
      );

      /* return {
        newVersionBanner
      }; */
    }
  }
);
